import { textileBaseUrl } from "@/config/env";
import request from '@/router/axios';

// 引入使用    import { SalesContractItemIList, SalesContractItemIById,SalesContractItemIAdd, SalesContractItemIEdit, SalesContractItemIDel} from "@/api/Sales/SalesContractItemApi";
//显示列表查询
export const SalesContractItemIList = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSalesContractItems",
        method: "get",
        params: {
           ...row
        }
    })
}

//根据ID获取单条信息
export const SalesContractItemIById = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/GetSalesContractItem",
        method: "get",
        params: {
            id
        }
    })
}

//添加
export const SalesContractItemIAdd = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PostSalesContractItem",
        method: "post",
        data: {
            ...row
        }
    })
}


//更新
export const SalesContractItemIEdit = (row) => {
    return request({
        url: textileBaseUrl + "sales/Sales/PutSalesContractItem",
        method: "put",
        data: {
            ...row
        }
    })
}

//删除
export const SalesContractItemIDel = (id) => {
    return request({
        url: textileBaseUrl + "sales/Sales/DeleteSalesContractItem",
        method: "delete",
        params: {
            id
        }
    })
}



